import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { cogs, leaf } from "react-icons-kit/fa";
import {
  faJava,
  faJsSquare,
  faConnectdevelop
} from "@fortawesome/free-brands-svg-icons";

import TechBox from "components/CustomWidgets/TechBox";
import RecentArticle from "components/CustomWidgets/RecentArticle";
import {
  getSearchResultsOrderedById,
  getObjectFromJSONByValue
} from "components/common/CommonFunctions.jsx";

const Image = ({ srcData }) => (
  <React.Fragment>
    <img
      src={getObjectFromJSONByValue(srcData, "type", "og:image")[0].content}
      style={{
        width: "100%",
        minHeight: "150px",
        maxHeight: "150px"
      }}
      alt={getObjectFromJSONByValue(srcData, "type", "og:title")[0].content}
    />
  </React.Fragment>
);

Image.propTypes = {
  srcData: PropTypes.array
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    document.title =
      "Learning Java | Free Java Tutorials from Experts | Java Tips & Tricks";
    let meta = document.createElement("meta");
    meta.name = "description";
    meta.content =
      "Learn Java from industry experts, and Utilize our Java tutorial to learn the popular language. Best Java tutorials for every programmer.";
    document.getElementsByTagName("head")[0].appendChild(meta);
    this.setState({
      data: getSearchResultsOrderedById().slice(0, 3)
    });
  }

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12" style={{ marginTop: "60px" }}>
              <img
                src="img/learninjava-banner.jpg"
                height="350px"
                width="100%"
                alt="learninjava-banner"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <TechBox
                  icon={faConnectdevelop}
                  iconType="faIcon"
                  color="#428bca"
                  header="Artificial Intelligence"
                  desc="Most happening technology in the world. Learn AI and ML with us."
                  link="/artificial-intelligence/"
                />
                <TechBox
                  icon={faJava}
                  iconType="faIcon"
                  color="#086eb6"
                  header="Java"
                  desc="Learn Java from industry experts, and Utilize our Java tutorial to learn the popular language."
                  link="/java-programming/"
                />
                <TechBox
                  icon={cogs}
                  color="#416284"
                  header="Web Services"
                  desc="Learn how to create and consume web services using Java."
                  link="/webservices/"
                />
                <TechBox
                  icon={leaf}
                  color="#68bd45"
                  header="Spring & Spring Boot"
                  desc="Learn how spring and spring boot will help and optimize your development experience."
                  link="/spring/"
                />
                <TechBox
                  icon={faJsSquare}
                  iconType="faIcon"
                  color="#e5a234"
                  header="JavaScript"
                  desc="Learn how to use javascript to create cool UI and other JS framework related articles here."
                  link="/javascript/"
                />
              </div>
            </div>
          </div>
          {/* divider */}
          <div className="row">
            <div className="col-md-12">&nbsp;</div>
          </div>
          {/* end divider */}
          <div className="row">
            <div className="col-md-12">
              <h4 className="heading">Popular Categories</h4>
              {/* divider */}
              <div className="row">
                <div className="col-md-12">
                  <div className="solidline"></div>
                </div>
              </div>
              {/* end divider */}
              <div className="row">
                <div className="col-md-12">
                  <section id="projects">
                    <ul id="thumbnails" className="portfolio">
                      <RecentArticle
                        title="Artififial Intelligence"
                        imgLink="https://github.com/learninjavagithub/assets/raw/master/artificial-intelligence-small.jpg"
                        altText="artificial-intelligence-small.jpg"
                        link="/artificial-intelligence/"
                      />
                      <RecentArticle
                        title="Java"
                        imgLink="https://github.com/learninjavagithub/assets/raw/master/java-banner-small.jpg"
                        altText="java-banner-small.jpg"
                        link="/java-programming/"
                      />
                      <RecentArticle
                        title="Webservices"
                        imgLink="https://github.com/learninjavagithub/assets/raw/master/webservices-banner-small.jpg"
                        altText="webservices-banner-small.jpg"
                        link="/webservices/"
                      />
                      <RecentArticle
                        title="Spring"
                        imgLink="https://github.com/learninjavagithub/assets/raw/master/spring-banner-small.jpg"
                        altText="spring-banner-small.jpg"
                        link="/spring/"
                      />
                      <RecentArticle
                        title="ReactJS"
                        imgLink="https://github.com/learninjavagithub/assets/raw/master/react-banner-small.jpg"
                        altText="react-banner-small.jpg"
                        link="/react/"
                      />
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h4 className="heading">Recent Articles</h4>
              {/* divider */}
              <div className="row">
                <div className="col-md-12">
                  <div className="solidline"></div>
                </div>
              </div>
              {/* end divider */}
              <div className="row">
                <div className="col-md-12">
                  <section id="projects">
                    <ul id="thumbnails" className="portfolio">
                      {data &&
                        data.map((content, key) => (
                          <React.Fragment key={key}>
                            <div className="col-md-4">
                              <div className="box">
                                <div className="overlay-container">
                                  <div
                                    className="thumbnail"
                                    style={{
                                      padding: 0,
                                      border: 0
                                    }}
                                  >
                                    <Link
                                      to={content.url}
                                      className="list-group-item allRadius"
                                      style={{ padding: 0 }}
                                    >
                                      <Image srcData={content.meta} />
                                      <div
                                        className="caption"
                                        style={{
                                          padding: 10,
                                          borderBottomWidth: "3px"
                                        }}
                                      >
                                        <h5
                                          className="list-group-item-heading"
                                          style={{ color: "#333" }}
                                        >
                                          {content.title}
                                        </h5>
                                        <p
                                          className="list-group-item-text lightTextBold"
                                          style={{
                                            paddingTop: "10px",
                                            paddingBottom: "10px"
                                          }}
                                        >
                                          {content.desc.slice(0, 150) + "..."}
                                        </p>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HomePage;
