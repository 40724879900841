import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import GoogleAdContainer from "components/Ads/GoogleAdContainer";
import {
  getSearchResultsByAny,
  getSearchResultsByCriteria,
  findAndRemove,
  getObjectFromJSONByValue
} from "components/common/CommonFunctions.jsx";

function getData(title, category) {
  if (category === undefined) {
    return getSearchResultsByAny();
  } else {
    let data = getSearchResultsByCriteria({
      tag: "true",
      criteria: category
    });
    let result = findAndRemove(data, "title", title);
    return result === "" ? getSearchResultsByAny() : result;
  }
}

const Image = ({ srcData }) => (
  <React.Fragment>
    <img
      src={getObjectFromJSONByValue(srcData, "type", "og:image")[0].content}
      width="150"
      height="100"
      style={{ width: "100%" }}
      alt={getObjectFromJSONByValue(srcData, "type", "og:title")[0].content}
    />
  </React.Fragment>
);

Image.propTypes = {
  srcData: PropTypes.array
};

class Right extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.setState({
      data: getData(this.props.title, this.props.category).slice(0, 10)
    });
  }

  render() {
    const { data } = this.state;
    return (
      <div className="rectangle-right col-md-2" style={{ marginTop: "10px" }}>
        <div
          className="largeTextBold"
          style={{
            textAlign: "center"
          }}
        >
          <div className="well well-sm">You may also like</div>
        </div>
        {data &&
          data.map((content, key) => (
            <React.Fragment key={key}>
              <div
                className="thumbnail"
                style={{
                  padding: 0,
                  border: 0
                }}
              >
                <Link
                  to={content.url}
                  className="list-group-item allRadius"
                  style={{ padding: 0 }}
                >
                  <Image srcData={content.meta} />
                  <div
                    className="caption"
                    style={{
                      padding: 0,
                      borderBottomWidth: "3px"
                    }}
                  >
                    <h5
                      className="list-group-item-heading"
                      style={{ color: "#333" }}
                    >
                      {content.title}
                    </h5>
                    <p className="list-group-item-text lightTextBold">
                      {content.desc.slice(0, 60) + "..."}
                    </p>
                  </div>
                </Link>
              </div>
            </React.Fragment>
          ))}
        <GoogleAdContainer position="right1" />
      </div>
    );
  }
}

Right.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string
};

export default Right;
