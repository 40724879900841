import React from "react";
import PropTypes from "prop-types";

import Icon from "react-icons-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RenderIcon = ({ name, size, type, color, altTxt }) => (
  <React.Fragment>
    {type === "noUserIcon" && (
      <span className="fa-stack fa-2x">
        <i className="fa fa-circle fa-stack-2x"></i>
        <i className="fa fa-user fa-stack-1x fa-inverse"></i>
      </span>
    )}
    {type === "circleImage" && (
      <img src={name} className="circleImage" alt={altTxt} />
    )}
    {type === "letter" && <span className="fa circleLetter" letter={name} />}
    {type === "iconRounded" && (
      <span id="iconRounded">
        <i style={{ color: color }} className={name + " " + size} />
      </span>
    )}
    {type === "icon" && (
      <Icon icon={name} style={{ color: color }} size={size} />
    )}
    {type === "url" && (
      <img
        src={name}
        alt={altTxt}
        style={{ color: color }}
        height={size + 10}
        width={size + 10}
      />
    )}
    {type === "faIcon" && (
      <span>
        <FontAwesomeIcon icon={name} style={{ fontSize: size, color: color }} />
      </span>
    )}
  </React.Fragment>
);

RenderIcon.defaultProps = {
  size: 24,
  type: false,
  altTxt: ""
};

RenderIcon.propTypes = {
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  color: PropTypes.string,
  altTxt: PropTypes.string
};

export default RenderIcon;
