import React from "react";
import PropTypes from "prop-types";

class OutputBox extends React.PureComponent {
  render() {
    const { title, header, content, ...rest } = this.props;
    return (
      <div style={{ marginTop: "50px" }}>
        <b>{header !== "" ? header : title}</b>
        <br />
        <div
          {...rest}
          dangerouslySetInnerHTML={{
            __html: content.replace(/[\r\n]/g, "<br />")
          }}
        />
      </div>
    );
  }
}

OutputBox.defaultProps = {
  title: "Output:",
  header: ""
};

OutputBox.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string
};

export default OutputBox;
