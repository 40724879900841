import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import ErrorBoundary from "components/CustomWidgets/ErrorBoundary";

const pubId = "ca-pub-8305178874731943";

const adIds = {
  right1: "3477907283",
  right2: "1591661146",
  left1: "4523589160",
  middle1: "9851743949",
  middle2: "8538662274",
  middle3: "7835954696",
  middle4: "3896709689",
  inarticlead: "8821926823",
  square1: "5836670838",
  square2: "6631137350",
  popup: "5190468229",
  bottom: "2470782023",
};

const initAd = () => {
  // eslint-disable-next-line no-undef
  $(document).ready(function () {
    $("ins").each(function () {
      if ($(this).css("display") === "none") {
        $(this).remove();
      }
    });
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  });
};

class GoogleAdContainer extends React.Component {
  shouldComponentUpdate(nextProps) {
    const {
      props: { path },
    } = this;
    return nextProps.path !== path;
  }

  // For Refresh
  componentDidUpdate() {
    if (this.props.enable) {
      initAd();
    }
  }

  // For initial load
  componentDidMount() {
    if (this.props.enable) {
      initAd();
    }
  }

  render() {
    const { path, position } = this.props;

    return (
      <React.Fragment key={path}>
        <ErrorBoundary>
          {this.props.enable ? (
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client={pubId}
              data-ad-slot={adIds[position]}
              data-ad-format="auto"
              data-full-width-responsive="true"
            />
          ) : (
            ""
          )}
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

GoogleAdContainer.defaultProps = {
  enable: true,
  position: "middle1",
};

GoogleAdContainer.propTypes = {
  enable: PropTypes.bool,
  path: PropTypes.object,
  position: PropTypes.string,
};

export default GoogleAdContainer;
