import React, { lazy, Suspense } from "react";
import "babel-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "App.css";

import Header from "components/layouts/Header.jsx";
import Routes from "components/common/Routes";

const LazyFooterComponent = lazy(() => import("./Footer"));
const renderLoader = () => <p>Loading</p>;

const FooterComponent = () => (
  <Suspense fallback={renderLoader()}>
    <LazyFooterComponent />
  </Suspense>
);

function requireAuth(Component) {
  class AuthenticatedComponent extends React.Component {

    render() {
      return <Component {...this.props} />;
    }
  }
  return withRouter(AuthenticatedComponent);
}

const switchRoutes = (
  <Switch>
    {Routes.map((prop, key) => {
      return (
        <Route
          exact
          path={prop.path}
          component={requireAuth(prop.component)}
          key={key}
        />
      );
    })}
  </Switch>
);

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div>{switchRoutes}</div>
        <FooterComponent />
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
