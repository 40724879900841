import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["ollamaDeepSeekLocal"],
  ...allHashTags,
  channel: "31"
};

const data = [
  {
    type: "toc",
    title: "AI Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> 2 Ways to run Ollama with DeepSeek-R1 or Tulu-3 locally - Using Docker and Directly on Local Machine [This article]</h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> <MyLink class='homelink' href='/how-to-test-deepseek-r1-tulu3-locally-with-openwebui-lmstudio/' target='_blank'>" +
      "How to test your LLMs locally with GUI tools, Open WebUI and LM Studio</MyLink></h4>" +
      "<h4><b>Part 3 : </b> " +
      "How to build and run your own DeepSeek-R1 RAG locally with LangChain [Upcoming]</h4>"
  },
  {
    type: "subheading",
    name: "introduction",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "Artificial Intelligence is the most happening technology in the world today. " +
      "Ever dreamed of having your own personal LLM all running in your own machine ? " +
      "This quick tutorial will show you how to use Ollama to run DeepSeek R1 LLM locally in two different ways. "
  },
  {
    type: "subheading",
    name: "why-run-local-LLM",
    title: "Why should you run LLM locally ?"
  },
  {
    type: "text",
    content:
      "Data security and privacy is a big concern today for any organization. Even individuals are more concerned about their privacy information " +
      "being transmitted over the internet. " +
      "This has lead to the rise of using personal computers to run LLMs. " +
      "<div class='lineBreak' /> " +
      "Alright, Now, lets see how to install and run <code style='white-space: normal'>Ollama</code> then run <code style='white-space: normal'>DeepSeek R1</code> locally. " +
      "For this example, we will try to install on MAC M3 system with 16GB RAM."
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "ollama-local-installation",
    title: "Way 1: Run Ollama directly on your local machine"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 1: Navigate and download <MyLink class='homelink' href='https://ollama.com/download/mac' target='_blank'>" +
      "Ollama</MyLink></h3> You can either move the downloaded file to Applications folder or run from a custome folder. " +
      "Click on the application. This will start the software and a small icon will appear in the menu bar on the top."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 2: Verify the installation of Ollama</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>ollama --version</code>"
  },
  {
    type: "output",
    content: "ollama version is 0.5.7\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 3: Run the DeepSeek-R1 or Tulu-3model</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />For this example, we will run the small versions of DeepSeek-R1 model, DeepSeek R1:1.5b and Tulu-3."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>ollama run deepseek-r1:1.5b</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>ollama run tulu3</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />You should see something like this. The command will pull the LLM model and runs with a prompt to start typing."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-install-deepseek-r1.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />There are multiple versions of DeepSeek R1 model available. You can choose the one that fits your requirement." +
      "Currently, the DeepSeek models are based on <code style='white-space: normal'>Qwen</code> (from Alibaba Cloud) and " +
      "<code style='white-space: normal'>Llama</code> (from Meta) models."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 4: List all available models</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>ollama list</code>"
  },
  {
    type: "output",
    content:
      "deepseek-r1:1.5b    a42b25d8c10a    1.1GB     9 minutes ago\r\n" +
      "Llama3.1:Latest     46e0c10c039e    4.9GB     2 weeks ago\r\n"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />I have two models. Let's try to delete one of them."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>ollama rm Llama3.1:Latest</code>"
  },
  {
    type: "output",
    content: "deleted 'Llama3.1:Latest'\r\n"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Step 5: Test the model</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Go ahead and type something in the command prompt. You should see the response from the model."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-test-deepseek-r1.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 6: Show model info</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>ollama show deepseek-r1:1.5b</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-show-deepseek-r1.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Step 7: Stop Ollama</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>sudo pkill -9 ollama Ollama</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />This should stop the Ollama process and the application icon will be removed from the menu bar."
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "subheading",
    name: "ollama-local-docker-installation",
    title: "Way 2: Run Ollama on local docker container"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /> " +
      "We can also run Ollama on a docker container instead of directly running on your local machine. "
  },
  {
    type: "subheading",
    name: "why-run-local-LLM-docker",
    title: "Why should you run LLM in docker locally ?"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Some people complained about Ollama seems to have uninstalled some display drivers. In order to keep your system configuration " +
      "separete from your development evnironment, it is recommended to run Ollama on a docker container. It is also " +
      "easy to run and manage Ollama on a docker container. So, Lets get started. We are using Docker Desktop for this example."
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 1: Pull the Ollama docker image - This will create an ollama docker image with name ollama/ollama</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker pull ollama/ollama</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-docker-image.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 2: Create a container with the above image and make it accessible from outside</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker run -d -v /Users/lij/.ollama:/root/.ollama -p 8080:11434 --name ollama-cont ollama/ollama</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-docker-container.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-docker-container-logs.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 3: Pull and Run the Ollama LLM image - By default the model is tinyllama</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker exec -it ollama-cont ollama run tinyllama</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-docker-run.jpg"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-docker-prompt.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />You can run any LLM using the above command. TinyLlama is the default and small in size. " +
      "So are its brains as you can see from the below answers. So get a better one instead :)"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker exec -it ollama-cont ollama run tulu3</code>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/ollama-docker-tulu-prompt.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 4: Test via exposed APIs</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>curl http://localhost:8080/api/tags</code>"
  },
  {
    type: "output",
    content: `{"models":[{"name":"tinyllama:latest","model":"tinyllama:latest","modified_at":"2025-02-02T08:47:23.395532857Z","size":637700138,"digest":"2644915ede352ea7bdfaff0bfac0be74c719d5d5202acb63a6fb095b52f394a4","details":{"parent_model":"","format":"gguf","family":"llama","families":["llama"],"parameter_size":"1B","quantization_level":"Q4_0"}},{"name":"deepseek-r1:1.5b","model":"deepseek-r1:1.5b","modified_at":"2025-01-31T06:41:35.106883152Z","size":1117322599,"digest":"a42b25d8c10a841bd24724309898ae851466696a7d7f3a0a408b895538ccbc96","details":{"parent_model":"","format":"gguf","family":"qwen2","families":["qwen2"],"parameter_size":"1.8B","quantization_level":"Q4_K_M"}}]}`
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/easy-guide-to-ssl/",
    previousText: "Easy Guide to SSL - All the terms you need to know",
    nextURL: "/how-to-test-deepseek-r1-tulu3-locally-with-openwebui-lmstudio/",
    nextText: "How to test your LLMs locally with GUI tools, Open WebUI and LM Studio"
  }
];

class OllamaDeepSeekLocal extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default OllamaDeepSeekLocal;
