import React from "react";

import SocialMediaIcons from "components/CustomWidgets/SocialMediaIcons";

class Contact extends React.PureComponent {

  render() {
    return (
      <React.Fragment>
        <div className="header-bar"></div>
        <div
          className="container-fluid"
          style={{
            minHeight: "650px",
            maxWidth: "500px",
            position: "relative"
          }}
        >
          <div
            className="row"
            style={{
              textAlign: "center"
            }}
          >
            <div>
              <div>
                <h1 style={{ color: "#ef6c00" }}>Why Learninjava ?</h1>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="displayTextLight">
                This website was created to assist developers in overcoming the
                challenges they face on a daily basis. Unfortunately, online
                solutions are often incomplete or inaccurate, leading to
                frustration and wasted time. We wanted to understand this pain
                point firsthand, having experienced similar difficulties during
                our own development journey. Our goal is to provide reliable,
                working solutions that save developers time and effort. To
                achieve this, we've made all content on our website free and
                accessible, including downloadable source code that's ready to
                use.
                <div className="lineBreak" />
                We're committed to sharing knowledge without restrictions or
                licenses. Our content is free to read, modify, and share, and we
                encourage you to spread the word. If you find our resources
                helpful, we'd appreciate if you could give us credit if you
                could share them with others. If you're the owner of a website
                owner, we kindly request that you include a link to our website
                so that others can benefit from our content.
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h3>Disclaimer</h3>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="displayTextLight">
                No birds (Angry Birds) or animals (Pigs) were harmed during the
                making of this website or its content. All the birds and animal
                characters used in some articles of this website are fictitious.
                Any resemblance to real birds/animals, living or dead, is purely
                coincidental :)
                <div className="lineBreakSmall" />
                All trademarks and logos belongs to their owners.
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h4>Contact us at:</h4>
                <h5>
                  <a
                    className="homelink"
                    href="mailto:contactus@learninjava.com"
                  >
                    contactus@learninjava.com
                  </a>
                </h5>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
              <div>
                <h4>Like, share or comment at: </h4>
              </div>
              <div className="col-md-12">
                <div className="solidlineLight"></div>
              </div>
              <div className="col-md-12">
                <SocialMediaIcons color="themed" type="square" size={48} />
              </div>
              <div className="col-md-12">
                <div className="solidlineLightBottom"></div>
              </div>
              <div className="col-md-12">&nbsp;</div>
              <div className="col-md-12">&nbsp;</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
