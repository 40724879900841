const headerAttrs = {
  stream: {
    title: "Java 8 - Stream",
    metaTitle:
      "Java Streams | Java 8 Streams | When to Use Java Streams - Tutorial",
    desc:
      "Java 8 has introduced a new concept called Stream. These are a " +
      "group of computations specified as a sequence of steps. Although, " +
      "the name sounds similar to Java IO API classes like InputStream/OutputStream, " +
      "this has nothing to do with them",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/java8-stream"
  },
  functionalInterfaces: {
    title: "Java 8 - Functional Interface",
    metaTitle:
      "Functional Interfaces in Java 8 | Java Functional Interface Tutorial",
    desc:
      "A functional interface is any interface with only one method in it. Java 8 " +
      "has introduced a new annotation called @FunctionalInterface. This annotation is " +
      "used to check for compile time type safety on functional interfaces",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/java8-functional-interfaces"
  },
  lambdaExpressions: {
    title: "Java 8 - Lambda Expressions",
    metaTitle: "Lambda Expressions in Java | Java Lambda Expressions Tutorial",
    desc:
      "Java 8 Lambda Expressions treats functionality as method arguments or code as data. " +
      "Very difficult definition to understand ? For Me too!! Simply said, lambda expressions " +
      "simplifies the syntax when we have only one method in an interface which needs to be called",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/java8-lambda-expressions"
  },
  equalsAndHashCodeOverride: {
    title: "Equals and HashCode Override",
    metaTitle: "Why You Should Override Equals and HashCode in Java?",
    desc:
      "The reason why you should override hashcode whenever you override equals is that " +
      "you need a way to create the same object you want to find. This is particularly " +
      "useful when you are dealing with collections.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/equals-and-hashcode.jpg",
    url: "https://www.learninjava.com/why-equals-and-hashcode-override"
  },
  threadStates: {
    title: "Thread States - Life cycle of a Thread",
    metaTitle:
      "What are the different Thread States in Java - Life cycle of a Thread",
    desc:
      "Java Thread States explained in a simple and easy to understand approach. Blocked state " +
      "is where we see the threads awaiting for some response. A thread can be in waiting " +
      "state when it requires a lock but is currently being used by some other thread",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/thread-states.jpg",
    url: "https://www.learninjava.com/thread-states"
  },
  userThreadDaemonThread: {
    title: "User and Daemon Threads",
    metaTitle:
      "What are User Threads and Daemon Threads in Java | Learn User and Daemon Threads",
    desc:
      "A User thread is a thread that is created by the User i.e, the application where " +
      "as a Daemon thead is a thread that is created to serve the user thread. A Daemon " +
      "thread is usually made to run in the background continuously",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/user-thread-and-daemon-thread"
  },
  restfulWebserviceUsingCXF: {
    title: "JAX-RS Restful WebService using CXF",
    metaTitle:
      "Restful Web Service Using Java JAX-RS, CXF | Creating JAX-RS Web Service using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-RS restful " +
      "webservice using Spring and CXF. For your convenience, we " +
      "have provided configurations for running the webservice on both " +
      "Jetty and Tomcat servers.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url: "https://www.learninjava.com/restful-webservice-using-cxf"
  },
  restfulClientUsingCXF: {
    title: "JAX-RS Restful Client using CXF",
    metaTitle:
      "Restful Client Using Java JAX-RS, CXF | Creating JAX-RS Client using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-RS restful " +
      "client using Spring and CXF. In our JAX-RS webservice using CXF " +
      "tutorial, we have created a restful webservice that contains two methods. " +
      "One method accepts text type input while the second one accepts a JsonBean type",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-json.png",
    url: "https://www.learninjava.com/restful-client-using-cxf"
  },
  jaxwsWebserviceUsingCXF: {
    title: "JAX-WS SOAP WebService using CXF",
    metaTitle:
      "WS SOAP Web Service Using Java CXF | Creating JAX-WS service using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-WS SOAP based " +
      "webservice using Spring and CXF. For your convenience, we " +
      "have provided configurations for running the webservice on both Jetty and Tomcat servers.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url: "https://www.learninjava.com/jaxws-soap-webservice-using-cxf"
  },
  jaxwsClientUsingCXF: {
    title: "JAX-WS SOAP Client using CXF",
    metaTitle:
      "WS SOAP Client Using Java CXF | Creating JAX-WS Client using CXF with Example",
    desc:
      "This tutorial explains how to create a JAX-WS SOAP based " +
      "client using Spring and CXF. There are number of ways you can test this SOAP webservice.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/jaxws-wizdler1.png",
    url: "https://www.learninjava.com/jaxws-soap-client-using-cxf"
  },
  restfulWebserviceUsingJersey: {
    title: "JAX-RS Restful WebService using Jersey",
    metaTitle:
      "RS Restful Web Service using Jersey | REST with Java (JAX-RS) using Jersey - Tutorial with Example",
    desc:
      "This tutorial explains how to create a JAX-RS Restful " +
      "webservice using Spring and Jersey. For your convenience, " +
      "we have provided configurations for running the webservice on " +
      "both Jetty and Tomcat servers.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url: "https://www.learninjava.com/restful-webservice-using-jersey"
  },
  restfulClientUsingJersey: {
    title: "JAX-RS Restful Client using Jersey",
    metaTitle:
      "RS Restful Client using Jersey | Restful Client with Java (JAX-RS) using Jersey - Tutorial with Example",
    desc:
      "This tutorial explains how to create a JAX-RS Restful " +
      "client using Spring and Jersey. We will try to create a client and " +
      "call the ping() method using Pure JAX-RS API without using Jersey and " +
      "also using Jersey API.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/poster-text.png",
    url: "https://www.learninjava.com/restful-client-using-jersey"
  },
  reactRouterApacheNginxTomcat: {
    title: "React Router Configuration - Apache Http Server, Nginx, Tomcat",
    metaTitle:
      "React Router: Router Configuration | How to React Routing with Apache Http Server, Nginx, Tomcat",
    desc:
      "This React post explains how to configure react router on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The react post explains client-side and server-side " +
      "routing and production ready config on three most famous servers: Apache HTTP Server, Nginx and Tomcat." +
      "Difference between BrowserRouter and HashRouter explained as well. Also has working" +
      "example and downloadable source code.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-router.jpg",
    url: "https://www.learninjava.com/react-router-apache-nginx-tomcat"
  },
  createReactAppReactRouter: {
    title:
      "React Router Configuration - Create React App - Step by Step Configuration",
    metaTitle:
      "Step by Step Tutorial for React Router Configuration on Create New React(CRA) App: ",
    desc:
      "This React post explains how to configure react router on create-react-app (CRA) " +
      "step by step with a working example and downloadable source code included." +
      "The working react sample application is easy to understand.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/react-routing-banner.jpg",
    url: "https://www.learninjava.com/create-react-app-react-router"
  },
  reactWidgetPackDisqus: {
    title:
      "React WidgetPack Comments - A Free Disqus alternative - Step by Step",
    metaTitle:
      "React WidgetPack Comments - A Free Disqus alternative - Step by Step",
    desc:
      "This react tutorial explains how to use widgetpack's comments widget as a free alternative to " +
      "disqus commenting system using React. The example application is provided for easy understanding. " +
      "It uses react-widgetpack-comments npm module. A full Commenting system can be implemented on your " +
      "website with just 1 or 2 lines of code.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/widgetpack-comments4.jpg",
    url: "https://www.learninjava.com/react-widgetpack-free-disqus-alternative"
  },
  angularRouterApacheNginxTomcat: {
    title: "Angular Router Configuration - Apache Http Server, Nginx, Tomcat",
    metaTitle:
      "Angular Router Configuration - Angular Routing on Apache Http Server, Nginx, Tomcat",
    desc:
      "This Angular post explains how to configure angular router on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The angular post explains client-side and server-side " +
      "routing and production ready config on three most famous servers: Apache HTTP Server, Nginx and Tomcat." +
      "Difference between HashLocationStrategy and PathLocationStrategy explained as well. Also has working" +
      "example and downloadable source code.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/angular-router-banner.jpg",
    url: "https://www.learninjava.com/angular-router-config-apache-nginx-tomcat"
  },
  angularRouterConfigCLIApp: {
    title: "Angular Router Configuration - Angular CLI App - Step by Step",
    metaTitle:
      "Step by Step Tutorial for Angular Router Configuration on Angular CLI App",
    desc:
      "This Angular tutorial explains how to configure angular router on Angular CLI, " +
      "generate application. The tutorial explains how to import @angular/router module and " +
      "configure the ng new basic application step by step.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/angular-router-banner.jpg",
    url: "https://www.learninjava.com/angular-router-config-cli-generate-app"
  },
  reactProxyApacheNginxTomcat: {
    title:
      "React Proxy Backend API configuration - Apache Http Server, Nginx, Tomcat",
    metaTitle:
      "React Proxy Backend API configuration | How To Proxy To React Backend API configuration in Apache Http Server, Nginx, Tomcat",
    desc:
      "This React post explains how to proxy backend API requests in react on Apache HTTP Server, " +
      "Nginx Server and Tomcat Server. The react post explains how to setup API proxy configuration " +
      "on create-react-app(CRA) application step by step. The proxy configuration is production ready and working.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/react-api-proxying-banner.jpg",
    url:
      "https://www.learninjava.com/react-proxying-api-backend-apache-nginx-tomcat"
  },
  reactReduxConfig: {
    title:
      "React Redux Configuration - How to configure React with Redux and Thunk ?",
    metaTitle:
      "React Redux Configuration | How to configure React with Redux and Thunk ?",
    desc:
      "This React post explains how to configure react and redux in a simple and easy to understand " +
      "approach. Explains what are Actions, Action Types, Reducers ? and what is Thunk middleware ? " +
      "The post includes working example which is production ready configuration.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/react-redux-banner.jpg",
    url: "https://www.learninjava.com/how-to-configure-react-and-redux"
  },
  soapMessageToObject: {
    title:
      "3 ways to convert SOAPMessage to Java Object with namespace and " +
      "XML to SOAPMessage",
    metaTitle:
      "3 ways to convert SOAPMessage to Java Object with namespace and " +
      "XML to SOAPMessage",
    desc:
      "This article provides one stop guide to convert SOAPMessage to Java Object with namespaces, " +
      "SOAPMessage as XML to SOAPMessage Object using Jaxb2Marshaller, " +
      "XMLStreamReader, JAXBContext and Document APIs",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/webservices-banner.jpg",
    url:
      "https://www.learninjava.com/3-ways-to-convert-soapmessage-to-java-object-with-namespace"
  },
  reactTableWithPagination: {
    title:
      "React Table - How to create a simple react table with pagination and sorting",
    metaTitle:
      "React Table - How to create a simple react table with pagination and sorting in ascending and " +
      "descending order",
    desc:
      "This tutorial explains with example, how to create a simple react table with pagination and sorting in ascending and " +
      "descending order in quick and easy step by step approach.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table.jpg",
    url:
      "https://www.learninjava.com/how-to-create-react-table-with-pagination-and-sorting"
  },
  reactLoadingSpinnerWithOverlay: {
    title: "Guide to create React Loading Spinner with Overlay",
    metaTitle: "React Loading Spinner with Overlay - Guide",
    desc:
      "This tutorial explains with examples, how to create a simple loading spinner using react " +
      "and font-awesome components with and without using an overlay created with react-loading-overlay component.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-loading-spinner2.jpg",
    url: "https://www.learninjava.com/react-loading-spinner-with-overlay"
  },
  reactTableWithLoadingSpinner: {
    title: "React Table - How to add a loading spinner",
    metaTitle: "How to add a loading spinner to React Table",
    desc:
      "This tutorial explains with working code, how to add a loading spinner to " +
      "react table.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-loading-spinner.jpg",
    url: "https://www.learninjava.com/react-table-with-loading-spinner"
  },
  webClientCRUD: {
    title:
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples",
    metaTitle:
      "How to create Spring WebClient CRUD - GET, POST, PUT and DELETE Examples",
    desc:
      "Spring WebClient - GET, PUT, POST, DELETE examples: Learn how to create, read, " +
      "update and delete using WebClient, an alternative to RestTemplate, examples " +
      "using GET, POST, PUT and DELETE http methods.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/spring-webclient-get-example.jpg",
    url:
      "https://www.learninjava.com/spring-webclient-get-post-put-delete-example"
  },
  webClientTesting: {
    title:
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer",
    metaTitle:
      "4 Ways to test WebClient using Mockito, MockWebServer, WebTestClient and WireMockServer",
    desc:
      "Mocking a WebClient in Spring: Learn how to test WebClient, examples include " +
      "testing using Mockito, MockWebServer, WebTestClient and WireMockServer.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/webclient-testing-mockito.jpg",
    url: "https://www.learninjava.com/4-ways-to-test-webclient-mocking"
  },
  reactTableWithRecordCount: {
    title: "React Table - How to add a Record Count",
    metaTitle: "How to add a Record Count to React Table",
    desc:
      "This tutorial explains with working code, how to add a record count to " +
      "react table.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-record-count.png",
    url: "https://www.learninjava.com/react-table-with-record-count"
  },
  reactTableWithNestedSubTable: {
    title: "React Table - How to create a nested sub table",
    metaTitle: "React Table - How to create a nested sub table",
    desc:
      "This tutorial explains with working code, how to create a nested child sub table " +
      "inside a react table.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/articles/react-table-with-nested-subtable.jpg",
    url: "https://www.learninjava.com/react-table-with-nested-subtable"
  },
  easyGuideToSSL: {
    title: "Easy Guide to SSL - All the terms you need to know",
    metaTitle: "Easy Guide to SSL - All the terms you need to know",
    desc:
      "A simple and easy guide to SSL concepts and all the terminology involved in SSL." +
      "The most confusing terms in SSL are explained in an easy to understand way.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/easy-guide-to-ssl"
  },
  pkcsAndPemToJks: {
    title: "Convert PKCS#12 to JKS and PEM to JKS format",
    metaTitle: "Convert PKCS#12 to JKS and PEM to JKS format",
    desc:
      "A simple and easy guide to convert PKCS#12(Public Key Cryptography Standards) " +
      "to JKS(Java Key Store) and PEM(Privacy Enhanced Mail) to JKS(Java Key Store) format.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url: "https://www.learninjava.com/convert-p12-to-jks-and-pem-to-jks"
  },
  tlsMutualAuth: {
    title:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI",
    metaTitle:
      "Two way SSL/Mutual Authentication - How to use client certificate in Postman and SOAP UI",
    desc:
      "Demonstrates how to configure and use TLS Mutual Authentication/Two way SSL Authentication/" +
      "Client Certificates in POSTMAN and SOAP UI.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/java-banner.jpg",
    url:
      "https://www.learninjava.com/tls-mutual-auth-two-way-ssl-postman-soapui"
  },
  ollamaDeepSeekLocal: {
    title:
      "2 ways to run Ollama DeepSeek Locally - Using Docker and Directly on Local Machine",
    metaTitle:
      "2 ways to run Ollama DeepSeek Locally - Using Docker and Directly on Local Machine",
    desc:
      "This tutorial explains 2 ways to run Ollama DeepSeek locally " +
      "using Docker and without using Docker.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/artificial-intelligence-banner.jpg",
    url: "https://www.learninjava.com/2-ways-to-run-ollama-deepseek-locally"
  },
  openWebUILMStudioLocal: {
    title:
      "How to test your LLMs locally with GUI tools, Open WebUI and LM Studio",
    metaTitle:
      "How to test your LLMs locally with GUI tools, Open WebUI and LM Studio",
    desc:
      "This article explains how to setup and run any LLM like DeepSeek-R1 or Tulu-3 locally using Open WebUI and LM Studio.",
    imgUrl:
      "https://github.com/learninjavagithub/assets/raw/master/artificial-intelligence-banner.jpg",
    url: "https://www.learninjava.com/how-to-test-deepseek-r1-tulu3-locally-with-openwebui-lmstudio"
  }
};

const generateHeader = (
  headerName,
  category,
  authorImage,
  iconColor,
  authoredDate
) => ({
  category: category,
  title: headerAttrs[headerName].title,
  metaTitle: headerAttrs[headerName].metaTitle,
  authorImage: authorImage,
  iconColor: iconColor,
  authorName: "learninjava",
  authoredDate: authoredDate,
  meta: [
    {
      type: "og:title",
      content: headerAttrs[headerName].title
    },
    {
      type: "og:description",
      content: headerAttrs[headerName].desc
    },
    {
      type: "og:image:secure_url",
      content: headerAttrs[headerName].imgUrl
    },
    {
      type: "og:image",
      content: headerAttrs[headerName].imgUrl
    },
    {
      type: "og:url",
      content: headerAttrs[headerName].url + "/"
    },
    {
      type: "canonical",
      content: headerAttrs[headerName].url
    },
    {
      type: "twitter:title",
      content: headerAttrs[headerName].title
    },
    {
      type: "twitter:description",
      content: headerAttrs[headerName].desc
    },
    {
      type: "twitter:image",
      content: headerAttrs[headerName].imgUrl
    }
  ]
});

const allHeaders = {
  stream: generateHeader(
    "stream",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Jan 11, 2015 - Java 8"
  ),
  functionalInterfaces: generateHeader(
    "functionalInterfaces",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Apr 15, 2016 - Java 8"
  ),
  lambdaExpressions: generateHeader(
    "lambdaExpressions",
    "java-banner",
    "fab-java",
    "#086eb6",
    "March 18, 2015 - Java 8"
  ),
  equalsAndHashCodeOverride: generateHeader(
    "equalsAndHashCodeOverride",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Jan 11, 2015 - Java"
  ),
  threadStates: generateHeader(
    "threadStates",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Mar 24, 2015 - Java"
  ),
  userThreadDaemonThread: generateHeader(
    "userThreadDaemonThread",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Mar 24, 2015 - Java"
  ),
  restfulWebserviceUsingCXF: generateHeader(
    "restfulWebserviceUsingCXF",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Aug 06, 2015 - Webservices"
  ),
  restfulClientUsingCXF: generateHeader(
    "restfulClientUsingCXF",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Aug 06, 2015 - Webservices"
  ),
  jaxwsWebserviceUsingCXF: generateHeader(
    "jaxwsWebserviceUsingCXF",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Apr 26, 2015 - Webservices"
  ),
  jaxwsClientUsingCXF: generateHeader(
    "jaxwsClientUsingCXF",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Aug 06, 2015 - Webservices"
  ),
  restfulWebserviceUsingJersey: generateHeader(
    "restfulWebserviceUsingJersey",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Aug 06, 2015 - Webservices"
  ),
  restfulClientUsingJersey: generateHeader(
    "restfulClientUsingJersey",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Aug 06, 2015 - Webservices"
  ),
  reactRouterApacheNginxTomcat: generateHeader(
    "reactRouterApacheNginxTomcat",
    "react-routing-banner",
    "fab-react",
    "#00d8fb",
    "Apr 18, 2020 - ReactJS"
  ),
  createReactAppReactRouter: generateHeader(
    "createReactAppReactRouter",
    "react-routing-banner",
    "fab-react",
    "#00d8fb",
    "Apr 28, 2020 - ReactJS"
  ),
  reactWidgetPackDisqus: generateHeader(
    "reactWidgetPackDisqus",
    "react-banner",
    "fab-react",
    "#00d8fb",
    "May 05, 2020 - ReactJS"
  ),
  angularRouterApacheNginxTomcat: generateHeader(
    "angularRouterApacheNginxTomcat",
    "angular-router-banner",
    "fab-angular",
    "#e42839",
    "May 18, 2020 - Angular"
  ),
  angularRouterConfigCLIApp: generateHeader(
    "angularRouterConfigCLIApp",
    "angular-router-banner",
    "fab-angular",
    "#e42839",
    "May 25, 2020 - Angular"
  ),
  reactProxyApacheNginxTomcat: generateHeader(
    "reactProxyApacheNginxTomcat",
    "react-api-proxying-banner",
    "fab-react",
    "#00d8fb",
    "Jun 07, 2020 - ReactJS"
  ),
  reactReduxConfig: generateHeader(
    "reactReduxConfig",
    "react-redux-banner",
    "fab-react",
    "#00d8fb",
    "Jul 21, 2020 - ReactJS"
  ),
  soapMessageToObject: generateHeader(
    "soapMessageToObject",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Aug 22, 2020 - Webservices"
  ),
  reactTableWithPagination: generateHeader(
    "reactTableWithPagination",
    "react-banner",
    "fab-react",
    "#00d8fb",
    "Oct 21, 2020 - ReactJS"
  ),
  reactLoadingSpinnerWithOverlay: generateHeader(
    "reactLoadingSpinnerWithOverlay",
    "react-banner",
    "fab-react",
    "#00d8fb",
    "Oct 24, 2020 - ReactJS"
  ),
  reactTableWithLoadingSpinner: generateHeader(
    "reactTableWithLoadingSpinner",
    "react-banner",
    "fab-react",
    "#00d8fb",
    "Nov 01, 2020 - ReactJS"
  ),
  webClientCRUD: generateHeader(
    "webClientCRUD",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Dec 05, 2020 - Webservices"
  ),
  webClientTesting: generateHeader(
    "webClientTesting",
    "webservices-banner",
    "fas-cogs",
    "#416284",
    "Dec 05, 2020 - Webservices"
  ),
  reactTableWithRecordCount: generateHeader(
    "reactTableWithRecordCount",
    "react-banner",
    "fab-react",
    "#00d8fb",
    "Dec 21, 2020 - ReactJS"
  ),
  reactTableWithNestedSubTable: generateHeader(
    "reactTableWithNestedSubTable",
    "react-banner",
    "fab-react",
    "#00d8fb",
    "Jan 05, 2021 - ReactJS"
  ),
  easyGuideToSSL: generateHeader(
    "easyGuideToSSL",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Feb 20, 2021 - Java"
  ),
  pkcsAndPemToJks: generateHeader(
    "pkcsAndPemToJks",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Feb 23, 2021 - Java"
  ),
  tlsMutualAuth: generateHeader(
    "tlsMutualAuth",
    "java-banner",
    "fab-java",
    "#086eb6",
    "Feb 27, 2021 - Java"
  ),
  ollamaDeepSeekLocal: generateHeader(
    "ollamaDeepSeekLocal",
    "artificial-intelligence-banner",
    "fab-connectdevelop",
    "#428bca",
    "Jan 27, 2025 - AI"
  ),
  openWebUILMStudioLocal: generateHeader(
    "openWebUILMStudioLocal",
    "artificial-intelligence-banner",
    "fab-connectdevelop",
    "#428bca",
    "Feb 08, 2025 - AI"
  )
};

export default allHeaders;
