import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import RenderIcon from "components/CustomWidgets/RenderIcon";

const MenuItem = props => (
  <React.Fragment>
    {props.isSubMenu === true ? (
      <li>
        <Link className="dropdown-item" to={props.link} replace={true}>
          {props.text}
        </Link>
      </li>
    ) : (
      <li>
        <Link style={{ fontSize: "14px" }} to={props.link} replace={true}>
          <RenderIcon
            name={props.icon}
            type={props.iconType}
            color={props.color}
            size={20}
          />
          &nbsp;&nbsp;<span className="menu-text">{props.text}</span>
        </Link>
      </li>
    )}
  </React.Fragment>
);

MenuItem.defaultProps = {
  iconType: "icon"
};

MenuItem.propTypes = {
  isSubMenu: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  text: PropTypes.string,
  iconType: PropTypes.string
};

export default MenuItem;
