import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "assets/css/giststyles.css";

class CustomGist extends PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.gist = props.gist;
    this.file = props.file;
    this.stylesheetAdded = false;
    this.state = {
      loading: true,
      src: ""
    };
  }

  // The Gist JSON data includes a stylesheet to add to the page
  // to make it look correct. `addStylesheet` ensures we only add
  // the stylesheet one time.
  addStylesheet = href => {
    if (!this.stylesheetAdded) {
      this.stylesheetAdded = true;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = href;

      document.head.appendChild(link);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    // Create a JSONP callback that will set our state
    // with the data that comes back from the Gist site
    var gistCallback = CustomGist.nextGistCallback();
    window[gistCallback] = function(gist) {
      if (this._isMounted) {
        this.setState({
          loading: false,
          src: gist.div
        });
      }
      this.addStylesheet(gist.stylesheet);
    }.bind(this);

    var url =
      "https://gist.github.com/" +
      this.props.gist +
      ".json?callback=" +
      gistCallback;
    if (this.props.file) {
      url += "&file=" + this.props.file;
    }

    // Add the JSONP script tag to the document.
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    document.head.appendChild(script);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  copyToClipboard = async (event) => {
    const button = event.currentTarget;
    const originalText = button.innerHTML;
    
    try {
      // Get the specific gist container that was clicked
      const gistContainer = button.closest('.gist-container');
      const gistFile = gistContainer.querySelector('.gist-file');
      const codeLines = gistFile.querySelectorAll('.js-file-line');
      const formattedContent = Array.from(codeLines)
        .map(line => line.textContent)
        .join('\n');
      
      await navigator.clipboard.writeText(formattedContent);
      
      // Show success state with React component instead of HTML string
      button.innerHTML = `<i class="fas fa-check"></i> Copied!`;
      button.style.backgroundColor = '#ef6c00';
      button.style.color = '#fff';
      
      // Reset after 2 seconds
      setTimeout(() => {
        button.innerHTML = originalText;
        button.style.backgroundColor = '';
        button.style.color = '';
      }, 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
      
      // Show error state with React component
      button.innerHTML = `<i class="fas fa-times"></i> Failed to copy`;
      button.style.backgroundColor = '#dc3545';
      button.style.color = '#fff';
      
      setTimeout(() => {
        button.innerHTML = originalText;
        button.style.backgroundColor = '';
        button.style.color = '';
      }, 2000);
    }
  };

  render() {
    if (this.state.loading) {
      return <div>loading...</div>;
    } else {
      return (
        <React.Fragment>
        <div className="gist-container">
          <button 
            onClick={this.copyToClipboard}
            className="gist-copy-button"
          >
            <FontAwesomeIcon icon={faCopy} /> Copy
          </button>
          <div dangerouslySetInnerHTML={{ __html: this.state.src }} />
        </div>
      </React.Fragment>
      );
    }
  }
}

CustomGist.propTypes = {
  gist: PropTypes.string.isRequired,
  file: PropTypes.string
};

// Each time we request a Gist, we'll need to generate a new
// global function name to serve as the JSONP callback.
var gistCallbackId = 0;
CustomGist.nextGistCallback = () => {
  return "embed_gist_callback_" + gistCallbackId++;
};

export default CustomGist;
