import React from "react";

import Article from "components/Templates/Article";
import allHeaders from "components/Properties/AllHeaders";
import allHashTags from "components/Properties/AllHashTags";

const header = {
  ...allHeaders["openWebUILMStudioLocal"],
  ...allHashTags,
  channel: "32"
};

const data = [
  {
    type: "toc",
    title: "AI Series - Table of Contents",
    content:
      "<div class='lineBreak' /> " +
      "<h4><b>Part 1 : </b> <MyLink class='homelink' href='/2-ways-to-run-ollama-deepseek-r1-tulu3-locally/' target='_blank'>" +
      "2 Ways to run Ollama with DeepSeek-R1 or Tulu-3 locally - Using Docker and Directly on Local Machine </MyLink></h4>" +
      "<div class='lineBreakSmall' /> " +
      "<h4><b>Part 2 : </b> " +
      "How to test your LLMs locally with GUI tools, Open WebUI and LM Studio [This article]<h4>" +
      "<h4><b>Part 3 : </b> " +
      "How to build and run your own DeepSeek-R1 RAG locally with LangChain [Upcoming]</h4>"
  },
  {
    type: "subheading",
    name: "introduction",
    title: "Introduction"
  },
  {
    type: "text",
    content:
      "In the last article, we have seen how to run Ollama DeepSeek locally with and without Docker. " +
      "Click <MyLink class='homelink' href='/2-ways-to-run-ollama-deepseek-r1-tulu3-locally/' target='_blank'>here</MyLink> to quickly read the article. " +
      "Alright, welcome back. So, now, you have installed and run Ollama locally. Let's see how to test your LLMs locally with GUI tools, Open WebUI and LM Studio."
  },
  {
    type: "subheading",
    name: "why-do-you-need-gui-tool-to-run-llm",
    title: "Why do you need a GUI tool to run an LLM ?"
  },
  {
    type: "text",
    content:
      "Although running a prompt from the command line is sufficient, it's generally recommended to use a graphical interface tool instead. " +
      "This is because a GUI tool offers a more user-friendly experience and provides options like choosing the model, prompt, or temperature settings. " +
      "<div class='lineBreak' /> " +
      "Additionally, you can save the history of the conversations and can easily share the results with others. "
  },
  {
    type: "googleAd",
    position: "middle1"
  },
  {
    type: "subheading",
    name: "open-webui-with-ollama-local-docker-installation",
    title: "Option 1: Install and Run Open WebUI with Ollama both as docker containers"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 1: Navigate and clone the repository: <MyLink class='homelink' href='https://github.com/open-webui/open-webui' target='_blank'>" +
      "Open WebUI</MyLink></h3> " +
      "The cloned repo already has a docker-composer.yaml file. We will try to modify this file slightly as per our needs like below."
  },
  {
    type: "gist",
    content: "119242653d5505442293058c0260082b"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><ul><li>The file has two services, one for Open WebUI and another for Ollama</li>" +
      "<li>The Ollama service has the configuration to pull the latest image and expose on port 11434</li>" +
      "<li>Additionally, there are health checks and command to start the ollama service</li>" +
      "<li>Open WebUI service uses Ollama service and start the UI</li></ul>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 2: Push both services to docker</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker compose up</code>"
  },
  {
    type: "text",
    content: "Or, if you want to run the ollama service separately, run the below command"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker run -d --network=host -v /user-home/open-webui:/app/backend/data -e OLLAMA_BASE_URL=http://127.0.0.1:11434 --name open-webui --restart always ghcr.io/open-webui/open-webui:main</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 3: Pull a model - Otherwise, by default, the ollama container would be empty:"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker exec -it ollama ollama pull deepseek-r1:1.5b</code>"
  },
  {
    type: "text",
    content: "Or, if you want to run via docker compose,"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker compose exec ollama ollama pull deepseek-r1:1.5b</code>"
  },
  {
    type: "text",
    content: "Or, just using a cURL command,"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>curl http://localhost:11435/api/pull -d '{\"name\": \"deepseek-r1:1.5b\"}'</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 4: Run the GUI, http://localhost:3000/auth</h3>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/open-webui.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Login and you should see the below screen. As you can see the deepseek-r1 model is already available."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/open-webui-home.jpg"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 5: Check container logs</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker compose logs -f ollama</code>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 5: Stop all containers</h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><code style='white-space: normal'>docker compose down</code>"
  },
  {
    type: "googleAd",
    position: "middle2"
  },
  {
    type: "subheading",
    name: "lm-studio-local-installation",
    title: "Option 2: Run any model directly in LM Studio - No code or commands needed"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 1: Navigate and download the LM Studio: <MyLink class='homelink' href='https://lmstudio.ai/' target='_blank'>" +
      "LM Studio</MyLink></h3> "
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />This is probably the easiest way to run an LLM. You dont need to run any commands or write any code. " +
      "Go ahead and run the downloaded executable. You should see something like below:"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-home.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 2: Click on \"Discover\" button in the left menu and download a model</MyLink></h3>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-download-model.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' /><h3>Step 3: Load the model</MyLink></h3>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-load-model.png"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Step 4: Open a new chat window and run a prompt</MyLink></h3>"
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-chat-prompt.png"
  },
  {
    type: "googleAd",
    position: "middle3"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Step 5: Click on \"Developer\" button in the left menu</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />This option has some developer friendly features. LM Studio has the ability to run a local server and " +
      "exposes an API to interact with the LLM. This can be used to run a prompt via an API. "
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-developer.png"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />Copy the cURL and run in any client like Postman/Bruno. We can also run the cURL directly in a terminal."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-local-server-curl.png"
  },
  {
    type: "text",
    content: "<div class='lineBreak' /><h3>Step 6: Click on \"My Models\" button in the left menu</MyLink></h3>"
  },
  {
    type: "text",
    content:
      "<div class='lineBreak' />All the available models are listed. You can edit the parameters or any configuration here."
  },
  {
    type: "image",
    content:
      "https://github.com/learninjavagithub/assets/raw/master/articles/ai/lm-studio-models-list.png"
  },
  {
    type: "googleAd",
    position: "middle4"
  },
  {
    type: "text",
    content: "<div class='lineBreak' />"
  },
  {
    type: "footnote"
  },
  {
    type: "navLinks",
    previousURL: "/2-ways-to-run-ollama-deepseek-r1-tulu3-locally/",
    previousText: "2 Ways to run Ollama with DeepSeek-R1 or Tulu-3 locally - Using Docker and Directly on Local Machine",
    nextURL: "/convert-p12-to-jks-and-pem-to-jks/",
    nextText: "Convert PKCS#12 to JKS and PEM to JKS format"
  }
];

class OpenWebUILMStudioLocal extends React.Component {
  render() {
    return (
      <>
        <Article header={header} data={data} />a
      </>
    );
  }
}

export default OpenWebUILMStudioLocal;
