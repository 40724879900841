import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import CustomGist from "components/CustomWidgets/CustomGist";
import OutputBox from "components/CustomWidgets/OutputBox";
import InfoBox from "components/CustomWidgets/InfoBox";

const MultiContentRenderer = ({ section }, i) => (
  <React.Fragment key={i}>
    {section.type === "text" && (
      <div
        className="displayText"
        dangerouslySetInnerHTML={{
          __html: section.content
        }}
      ></div>
    )}
    {section.type === "gist" && <CustomGist gist={section.content} /> }
    {section.type === "output" && (
      <OutputBox className="outputBlock" content={section.content} />
    )}
    {section.type === "info" && <InfoBox data={section.content} />}
  </React.Fragment>
);

MultiContentRenderer.propTypes = {
  section: PropTypes.object
};

export default MultiContentRenderer;
