import React from "react";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { tags } from "react-icons-kit/fa/tags";

import GoogleAdContainer from "components/Ads/GoogleAdContainer";
import allHashTags from "components/Properties/AllHashTags";
import SocialMediaIcons from "components/CustomWidgets/SocialMediaIcons";

class Left extends React.Component {
  render() {
    return (
      <div className="col-md-2" style={{ marginTop: "10px" }}>
        <div
          className="mediumTextBold mediumTextPadding"
          style={{ textAlign: "left" }}
        >
          <Icon icon={tags} size={20} />
          &nbsp; Click to search by hash tag
        </div>
        <div className="col-md-12">
          <div className="solidlineLight"></div>
        </div>
        <div className="hashTags" style={{ padding: "5px" }}>
          {allHashTags.hashTags.map((tag, key) => (
            <React.Fragment key={key}>
              <Link
                to={tag === "java" ? "/java-programming/" : "/" + tag + "/"}
                className={"badge btn-" + allHashTags.hashTagColors[key]}
              >
                #{tag}
              </Link>
            </React.Fragment>
          ))}
        </div>
        <p />
        <div className="col-md-12">
          <GoogleAdContainer position="square1" />
        </div>
        <div className="col-md-12">
          <div className="solidlineLight"></div>
        </div>
        <div className="col-md-12">
          <SocialMediaIcons color="themed" type="square" />
        </div>
        <div className="col-md-12">
          <div className="solidlineLightBottom"></div>
        </div>
        <div className="col-md-12">&nbsp;</div>
        <div className="col-md-12">
          <GoogleAdContainer position="square2" />
        </div>
        <div className="col-md-12">&nbsp;</div>
      </div>
    );
  }
}

export default Left;
